<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div id="October">
    <div class="img-1">
      <div class="text">
        <div>成长难免</div>
        <div>磕磕绊绊</div>
      </div>
      <div class="img-item">
        <img :src="res + '/imgs/xhl/24.jpg'">
      </div>
    </div>
    <div class="img-2">
      <div class="img-item">
        <img :src="res + '/imgs/xhl/21.jpg'">
      </div>
      <div class="text">
        <div>我超级</div>
        <div>勇敢</div>
      </div>
    </div>
    <MonthList :curIndex="pageData.index"/>
  </div>
</template>

<script>
import MonthList from '../components/month.vue'
import config from '../js/config.js'
export default {
  name: 'October',
  components: {
    MonthList
  },
  data() {
    return {
      res: config.res
    }
  },
  props: {
    pageData: {
      type: Object,
      default: function () {
        return {
          index: 0,
          title: "暂无"
        }
      }
    }
  },

}
</script>

<style lang="scss">
#October {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/10.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  position: relative;
  font-family: "XHL";
  .img-1{
    position: absolute;
    top: 2vh;
    right: 10vw;
    display: flex;
    width: 88vw;
    justify-content: space-between;
    align-items: center;
    .text {
      width: 47vw;
      font-size: 2rem;
      letter-spacing: 0.5rem;
      text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgba(0,0,0,0.5);
    }
    .img-item{
      display: flex;
      img{
        border-radius: 2rem;
        width: 40vw;
        filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
      }
    }
  }
  .img-2 {
    position: absolute;
    bottom: 2vh;
    right: 10vw;
    display: flex;
    width: 88vw;
    justify-content: space-between;
    align-items: center;
    .text {
      width: 45vw;
      font-size: 2rem;
      letter-spacing: 0.5rem;
      color: black;
      text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgba(0,0,0,0.5);
    }
    .img-item{
      display: flex;
      img{
        border-radius: 2rem;
        width: 40vw;
        filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
      }
    }
  }
}
</style>
