/**
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-17
 */

import wx from 'weixin-js-sdk';
import poster from '../assets/xhl.jpg'
import { getConfig } from './api.js'
import config from './config.js'
export function wxInit() {
  if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
    try {
      getConfig().then(res => {
        if (res.data && res.data.code === 0 && res.data.data) {
          let wxConfig = res.data.data
          wxConfig.debug = false;
          wxConfig.jsApiList = [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'onMenuShareAppMessage',
            'onMenuShareTimeline',
            'onMenuShareQQ',
            'onMenuShareQZone'
            ];
          wxConfig.openTagList = ['wx-open-launch-weapp', 'wx-open-launch-app'];
          wx.config(wxConfig)
          const title = config.title
          const date = new Date(config.time);
          const summary = (date.getMonth() + 1)+ "月"+date.getDate()+"日 "+date.getHours()+":"+(date.getMinutes() > 9 ? date.getMinutes() : "0"+date.getMinutes())+" \n" + config.address.name + " " + config.address.room
          const url = config.url
          const shareData = {
            title: title,
            link: url + "/",
            imgUrl: url + poster
          };
          const shareData2 = {
            title: title,
            link: url + "/",
            desc: summary,
            imgUrl: url + poster
          };
          wx.ready(() => {
            try {
               wx.updateAppMessageShareData(shareData2);
               wx.updateTimelineShareData(shareData);
            } catch(e) {
            }
          
          });
        }
      }).catch(e => {
      })
    } catch(e) {
    }
  }
}