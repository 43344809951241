<template>
  <div id="Baby">
    <audio id="music"  style="display: none" :src="music" loop="loop" controls="controls" autoplay="autoplay"></audio>
    <svg-icon :class="musicState === 1 ? 'music-icon': 'music-icon stop'" iconClass="music" />
    <div id="scroll-container" @touchstart="touchstart" @touchmove="touchmove" @touchend="touchend">
      <div class="main" v-if="scrollContainerKey">
        <div class="page prev">
          <component v-bind:is="getComponent(prevIndex()).component" :pageData=getComponent(prevIndex())></component>
        </div>
        <div class="page cur">
          <component v-bind:is="getComponent(curIndex).component" @start="start()" :pageData=getComponent(curIndex)></component>
        </div>
        <div class="page next">
          <component v-bind:is="getComponent(nextIndex()).component" :pageData=getComponent(nextIndex())></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JanuaryPage from './1.vue'
import FebruaryPage from './2.vue'
import March from './3.vue'
import April from './4.vue'
import MayPage from './5.vue'
import JunePage from './6.vue'
import JulyPage from './7.vue'
import AugustPage from './8.vue'
import September from './9.vue'
import October from './10.vue'
import November from './11.vue'
import Home from './home.vue'
import OneSt from './1st.vue'
import Loading from '../components/loading.vue'
import componentList from '../js/components'
import { wxInit } from '../js/wx.js'
import config from '../js/config.js'
export default {
  name: 'Baby',
  components: {
    Home,
    JanuaryPage,
    FebruaryPage,
    March,
    April,
    MayPage,
    JunePage,
    JulyPage,
    AugustPage,
    September,
    November,
    October,
    OneSt,
    Loading
  },
  data() {
    return {
      curIndex: 0,
      type: 0,
      scrollContainer: null,
      amine: false,
      loading: true,
      interval: null,
      scrollContainerKey: true,
      musicState: 0,
      music: config.res + '/music.mp3',
      isStart: false
    }
  },
  mounted() {
    this.musicInit()
    wxInit()
    this.scrollContainer = document.getElementById("scroll-container")
    this.scrollContainer.addEventListener("transitionend", (data) => {
      if (this.amine ){
        this.scrollContainer.classList.remove("down")
        this.scrollContainer.classList.remove("up")
        this.amine = false
        this.scrollContainerKey = false
        this.$nextTick(() => {
          if (this.type === 0) {
            this.curIndex = this.prevIndex()
          } else if (this.type === 1) {
              this.curIndex = this.nextIndex()
          }
            this.amine = false
            this.scrollContainerKey = true
          })
        }
    })
  },
  watch: {
    musicState:{
      handler: async function (val) {
        if (val === 1 && !this.isStart) {
           this.isStart = true
           setTimeout(() => {
            this.start()
           }, 10000)
        }
      },
      deep: true
    },
  curIndex:{
      handler: async function (val) {
        if (val === componentList.length && this.interval){
          this.clearInterval()
        }
      },
      deep: true
    },
  },
  methods: {
    musicInit() {
      const audio = document.getElementById('music');
      document.getElementsByClassName("music-icon")[0].addEventListener('click', () => {
       if (this.musicState === 1) {
         audio.pause()
         this.musicState = 0
       }else if (this.musicState === 0 && this.isStart === true) {
         audio.play()
         this.musicState = 1
       }
      })
      if (!audio) {
        this.musicState = 1
        return
      }
      audio.addEventListener('canplaythrough', async () => {
        if (!audio.currentTime || audio.currentTime === 0) {
          /*const promise = audio.play()
          if (!promise) {
            this.musicState = 1
            return
          }
          promise.then(res => {
            this.musicState = 1
            return
          }).catch(e => {
            this.musicState = 2
            return
          })*/
          try {
            this.musicState = 2
            await audio.play()
            this.musicState = 1
            return
          } catch (e) {
            this.musicState = 2
            return
          }
        } else {
          this.musicState = 1
          return
        }
      });
     },
    clearInterval() {
      if (this.interval) {
         window.clearInterval(this.interval);
      }
    },
    down() {
        this.scrollContainer.classList.add("down")
        this.type = 1
        this.amine = true
    },
    start() {
      if (this.musicState === 2) {
        document.getElementById('music').play()
        this.musicState = 1
      }
        this.down()
          this.interval = window.setInterval(() => {
            if (this.curIndex < componentList.length -1) {
              this.down()
            }
          }, 6000);
    },
    prevIndex() {
      return this.curIndex === 0 ? componentList.length -1 : this.curIndex - 1
    },
    nextIndex() {
      return this.curIndex === componentList.length -1 ? 0 : this.curIndex + 1
    },
    getComponent(index) {
      return componentList[index]
    },
    touchstart(e) {
      if (e.target.id === 'MapContainer') {
        return
      }
      this.startX = e.touches[0].pageX;
      this.startY = e.touches[0].pageY;
    },
    touchmove(e) {
      e.preventDefault();
      this.isMove = true;
      this.endX = e.touches[0].pageX;
      this.endY = e.touches[0].pageY;
    },
    touchend(e) {
      if (e.target.id === 'MapContainer') {
        return
      }
      let X = this.endX - this.startX,
          Y = this.endY - this.startY;
      this.type = -1
      if (this.isMove && !this.amine) {
        if (Y > 160 && Math.abs(Y) > Math.abs(X) && this.curIndex > 1 ) {
          //向下
          this.scrollContainer.classList.add("up")
          this.type = 0
          this.amine = true
          this.clearInterval()
        } else if (Y < -160 && Math.abs(Y) > Math.abs(X) && this.curIndex < componentList.length -1) {
          //向上
          this.down()
        } else if (Y > 160 && Math.abs(Y) > Math.abs(X)) {
          this.clearInterval()
        }
      }
    },
  }
}
</script>

<style lang="scss">
#Baby {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: relative;
  .music-icon {
    position: absolute;
    z-index: 9;
    font-size: 2.4rem;
    border-radius: 50%;
    filter: drop-shadow(10px 10px 20px rgba(225,115,77,0.5));
    animation: rotoImg 3s linear infinite;
    top: 1vh;
    right: 2vw;
  }
  .stop {
    transform: rotate(0deg) !important;
    animation: none !important;
    filter: grayscale(1) !important;
  }
  @keyframes rotoImg{
	    from{
	        transform: rotate(0deg);
          filter: drop-shadow(10px 10px 20px rgba(225,115,77,0.5));
	    }
	    to{
	        transform: rotate(360deg);
          filter: drop-shadow(10px 10px 30px rgba(225,115,77,0.3));
	    } 
	}
  .up{
    .prev {
      height: 100% !important;
    }
    .cur {
      transform: translateY(60%);
    }
  }
  .down{
    .next {
      height: 100% !important;
    }
    .cur {
      transform: translateY(-60%);
    }
  }

  #scroll-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    width: 100vw;
    position: relative;
    .page {
      overflow: hidden;
      height: 100%;
      width: 100%;
      font-size: 6rem;
      position: absolute;
      transition: 3.6s ease-in-out;
      color: white;
      text-align: center;
    }
    .prev {
      height: 0;
      z-index: 1;
      top: 0;
      //transform: translateY(-10%);
    }
    .cur {
      top: 0;
    }
    .next {
      height: 0;
      z-index: 1;
      bottom: 0;
      //transform: translateY(10%);
    }
  }
}
</style>
