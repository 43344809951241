/**
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-16
 */
let componentList = [{
    index: 0,
    title: "首页",
    color: 'red',
    component: 'Home',
    month: false
},{
    index: 1,
    title: "一月",
    color: 'green',
    component: 'JanuaryPage',
    month: true
},{
    index: 2,
    title: "二月",
    color: 'red',
    component: 'FebruaryPage',
    month: true
}, {
    index: 3,
    title: "三月",
    color: 'blue',
    component: 'March',
    month: true
}, {
    index: 4,
    title: "四月",
    color: 'yellow',
    component: 'April',
    month: true
}, {
    index: 5,
    title: "五月",
    color: 'black',
    component: 'MayPage',
    month: true
},{
    index: 6,
    title: "六月",
    color: 'red',
    component: 'JunePage',
    month: true
}, {
    index: 7,
    title: "七月",
    color: 'blue',
    component: 'JulyPage',
    month: true
}, {
    index: 8,
    title: "八月",
    color: 'yellow',
    component: 'AugustPage',
    month: true
}, {
    index: 9,
    title: "九月",
    color: 'black',
    component: 'September',
    month: true
},{
    index: 10,
    title: "十月",
    color: 'red',
    component: 'October',
    month: true
}, {
    index: 11,
    title: "十一月",
    color: 'blue',
    component: 'November',
    month: true
}, {
    index: 12,
    title: "一周岁",
    color: 'yellow',
    component: 'OneSt',
    month: true
}]

// let componentList = [{
//     index: 0,
//     title: "首页",
//     color: 'red',
//     component: 'Home',
//     month: false
// },{
//     index: 1,
//     title: "1",
//     color: 'green',
//     component: 'JanuaryPage',
//     month: true
// }, {
//     index: 12,
//     title: "2",
//     color: 'black',
//     component: 'OneSt',
//     month: true
// }]
export default componentList