<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div id="FebruaryPage">
    <div class="img-1">
      <img :src="res + '/imgs/xhl/2.jpg'">
    </div>
    <div class="img-2">
      <img :src="res + '/imgs/xhl/3.jpg'">
    </div>
    <div class="text">做个精致Boy</div>
    <MonthList :curIndex="pageData.index"/>
  </div>
</template>

<script>
import MonthList from '../components/month.vue'
import config from '../js/config.js'
export default {
  name: 'FebruaryPage',
  components: {
    MonthList
  },
  data() {
    return {
      res: config.res
    }
  },
  props: {
    pageData: {
      type: Object,
      default: function () {
        return {
          index: 0,
          title: "暂无"
        }
      }
    }
  },

}
</script>

<style lang="scss">
#FebruaryPage {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/2.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  color: white;
  font-family: "XHL";
  .img-1 {
    position: absolute;
    top: 2vh;
    left: 2vw;
    display: flex;
    filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
    img {
      border-radius: 2rem;
      width: 20rem;
    }
  }
  .img-2 {
    position: absolute;
    bottom: 2vh;
    right: 14vw;
    display: flex;
    filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
    img {
      border-radius: 2rem;
      width: 13rem;
    }
  }
  .text {
    position: absolute;
    top: 36vh;
    font-size: 2rem;
    left: 2vw;
    letter-spacing: 1rem;
  }
}
</style>
