<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div id="September">
    <div class="img">
      <div class="text">
        行走表情包
      </div>
      <div class="img-item">
        <img :class="count===1? 'cover': ''" :src="res + '/imgs/xhl/17.jpg'">
      </div>
      <div class="img-item">
        <img :class="count===2? 'cover': ''" :src="res + '/imgs/xhl/18.jpg'">
      </div>
      <div class="img-item">
        <img :class="count===3? 'cover': ''" :src="res + '/imgs/xhl/19.jpg'">
      </div>
      <div class="img-item">
        <img :class="count===4? 'cover': ''" :src="res + '/imgs/xhl/20.jpg'">
      </div>
      <div class="img-item">
        <img :class="count===5? 'cover': ''" :src="res + '/imgs/xhl/22.jpg'">
      </div>
      <div class="img-item">
        <img :class="count===6? 'cover': ''" :src="res + '/imgs/xhl/23.jpg'">
      </div>
    </div>
    <MonthList :curIndex="pageData.index"/>
  </div>
</template>

<script>
import MonthList from '../components/month.vue'
import config from '../js/config.js'
export default {
  name: 'September',
  components: {
    MonthList
  },
  data() {
    return {
      res: config.res,
      count: 2,
      interval: null
    }
  },
  mounted() {
   this.interval =  setInterval(() => {
      this.count = Math.floor(Math.random() * 6) + 1;
    }, 2000)
  },
  beforeDestroy() {
    if(this.interval) {
      window.clearInterval(this.interval);
    }
  },
  props: {
    pageData: {
      type: Object,
      default: function () {
        return {
          index: 0,
          title: "暂无"
        }
      }
    }
  },

}
</script>

<style lang="scss">
#September {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/9.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  position: relative;
  font-family: "XHL";
  .img{
    position: absolute;
    bottom: 8vh;
    left: 2vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 87vw;
    .text {
      position: absolute;
      top: -6rem;
      font-size: 3rem;
      text-align: center;
      width: 87vw;
      letter-spacing: 0.5rem;
      text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgba(0,0,0,0.5);
    }
    .img-item{
      display: flex;
      margin-bottom: 2vw;
      img{
        border-radius: 2rem;
        width: 28vw;
        filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
      }
      .cover {
        animation: rotoImg2 2s linear infinite;
      }
      @keyframes rotoImg2{
        0%{
          transform: rotateY(0deg);
        }
        50%{
          transform: rotateY(180deg);
        }
        100%{
          transform: rotateY(0deg);
        }
      }
    }
  }
}
</style>
