<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div id="JanuaryPage">
    <div class="img">
      <div class="text-1">初次见面</div>
      <img :src="res + '/imgs/xhl/1.jpg'">
      <div class="text-2">20230212</div>
    </div>
    <MonthList :curIndex="pageData.index"/>
  </div>
</template>

<script>
import MonthList from '../components/month.vue'
import config from '../js/config.js'
export default {
  name: 'JanuaryPage',
  components: {
    MonthList
  },
  data() {
    return {
      res: config.res
    }
  },
  props: {
    pageData: {
      type: Object,
      default: function () {
        return {
          index: 0,
          title: "暂无"
        }
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
#JanuaryPage {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  .img {
    color: white;
    font-family: "XHL";
    position: absolute;
    top: 16vh;
    left: 3vw;
    display: flex;
    flex-wrap: wrap;
    width: 20rem;
    .text-1 {
      width: 20rem;
      text-align:center;
      line-height: 6rem;
      font-size: 3rem;
      letter-spacing: 1rem;
      text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgba(0,0,0,0.5);
    }
    .text-2 {
      width: 20rem;
      text-align:center;
      line-height: 3rem;
      font-size: 1.6rem;
      letter-spacing: 1rem;
      text-shadow: 1px -1px #fff, -1px 1px #999, -2px 2px 3px rgba(0,0,0,0.3);
    }
    img {
      border-radius: 2rem;
      width: 20rem;
    }
  }
}
</style>
