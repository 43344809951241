/**
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
 */
import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import "./assets/style/index.scss";
import SvgIcon from './components/svg/svgIcon.vue'
import config from './js/config.js'
Vue.config.productionTip = false
Vue.component('svg-icon', SvgIcon)
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
document.title = config.title
import './components/svg/index'