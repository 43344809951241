/**
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-16
 */
import axios from 'axios';
import config from './config'
const defaultAxios = axios.create({
  timeout: 10000,
  withCredentials: true
});
export function getConfig() {
  const option = {
    url: "/wx/auth/wxConfig",
    method: "POST",
    data: {
      appCode: 'xb_studio',
      redirectUrl: config.url + '/'
    } 
  };
  return defaultAxios.request(option);
}