<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-17
-->
<template>
  <div class="month-list">
    <div :class="'month-item' + (item.index === curIndex ? ' active' : '')" v-for="item in months" :key="item.index">{{item.title}}</div>
  </div>
</template>

<script>
import componentList from '../js/components'
export default {
  name: 'MonthList',
  props: {
    curIndex: {
      type: Number,
      default: function () {
        return -1
      }
    }
  },
  data() {
    return {
      months: componentList.filter(d => {
        return d.month === true
      }),
    }
  }
}
</script>

<style lang="scss">
.month-list {
  font-family: 'XHL';
  overflow: hidden;
  height: 100vh;
  font-size: 11px;
  color: white;
  position: absolute;
  top: 0;
  right: 0.2rem;
  margin: 20vh 0;
  .month-item {
      line-height: 5vh;
      text-align: right;
      font-weight: bold;
      text-shadow: 1px 1px #999, 1px 1px #333;
  }
    .active {
      font-size: 1.2rem;
      font-weight: bold;
      text-shadow: 1px 1px #fff, 1px 1px #ddd ,1px 1px #999, 3px 3px 5px rgb(81 72 164);
    }
}
</style>
