/**
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-17
 */
const config = {
  title: '小海螺周岁宴邀请帖',
  time: 1706326200000,
  countTime: 10000,
  url: "https://xhl.iboot.tech",
  res: "http://ruke.site:92",
  address: {
    name: '澄迈蓝海钧华大饭店',
    room: '钧华厅',
    lat: 19.94437,
    lng: 110.118987,
    detail: '海南省澄迈县老城高新技术示范区疏港路9号'
  }
}
export default config