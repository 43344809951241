<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div id="JunePage">
    <div class="text">
      我叫小海螺
    </div>
    <div class="img-1">
      <img :src="res + '/imgs/xhl/12.jpg'">
    </div>
    <div class="img-2">
      <img :src="res + '/imgs/xhl/13.jpg'">
    </div>
    <MonthList :curIndex="pageData.index"/>
  </div>
</template>

<script>
import MonthList from '../components/month.vue'
import config from '../js/config.js'
export default {
  name: 'JunePage',
  components: {
    MonthList
  },
  data() {
    return {
      res: config.res
    }
  },
  props: {
    pageData: {
      type: Object,
      default: function () {
        return {
          index: 0,
          title: "暂无"
        }
      }
    }
  },

}
</script>

<style lang="scss">
#JunePage {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/6.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  position: relative;
  font-family: "XHL";
  .text {
    position: absolute;
    top: 2vh;
    font-size: 4rem;
    left: 8vw;
    width: 6rem;
    text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgba(0,0,0,0.5);
  }
  .img-1{
    position: absolute;
    top: 3vh;
    right: 10vw;
    display: flex;
    img{
      border-radius: 2rem;
      width: 50vw;
      filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
    }
  }
  .img-2{
    position: absolute;
    bottom: 6vh;
    left: 2vw;
    display: flex;
    img{
      border-radius: 2rem;
      width: 45vw;
      filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
    }
  }
}
</style>
