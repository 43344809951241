<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div id="November">
    <div class="text">
      一直可爱下去
    </div>
    <div class="img">
      <div class="img-item">
        <img :src="res + '/imgs/xhl/26.jpg'">
      </div>
      <div class="img-item">
        <img :src="res + '/imgs/xhl/25.jpg'">
      </div>
    </div>
    <MonthList :curIndex="pageData.index"/>
  </div>
</template>

<script>
import MonthList from '../components/month.vue'
import config from '../js/config.js'
export default {
  name: 'November',
  components: {
    MonthList
  },
  data() {
    return {
      res: config.res
    }
  },
  props: {
    pageData: {
      type: Object,
      default: function () {
        return {
          index: 0,
          title: "暂无"
        }
      }
    }
  },

}
</script>

<style lang="scss">
#November {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/11.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  position: relative;
  font-family: "XHL";
  .text {
    position: absolute;
    top: 2vh;
    font-size: 3rem;
    letter-spacing: 0.5rem;
    text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgba(0,0,0,0.5);
  }
  .img {
    position: absolute;
    bottom: 6vh;
    right: 10vw;
    width: 88vw;
    text-align: center;
    .img-item {
      margin-bottom: 2vh;
    }
    img{
      border-radius: 2rem;
      width: 72vw;
      filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
    }
  }
}
</style>
