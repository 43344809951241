<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
 <div class="loading">
      <div class="loading-svg"></div>
      <div class="loading-text">精彩马上开始，请调大音量</div>
 </div>
</template>

<script>
import lottie from 'lottie-web';
import datajson from '@/assets/lottie/loading.json';
export default {
  name: 'Loading',
  components: {
  },
  mounted() {
    lottie.loadAnimation({
        container: document.getElementsByClassName('loading-svg')[0], // 包含动画的dom元素
        renderer: 'svg', // 渲染出来的是什么格式
        loop: true, // 循环播放
        autoplay: true, // 自动播放
        animationData: datajson // 动画json的路径
      });
  },

}
</script>

<style lang="scss">
.loading {
    overflow: hidden;
    position: absolute;
    z-index: 3;
    text-align: center;
    width: 100vw;
    height: 100vh;
    padding-top: calc(50vh - 60vw);
    .loading-svg {
      overflow: hidden;
      margin: auto;
      width: 66vw;
      height: 66vw;
      border-radius: 50%;
    }
    .loading-text {
      font-family: 'XHL';
      color: $blue;
      font-size: 1.5rem;
      line-height: 14vw;
    }
  }
</style>
