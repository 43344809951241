<!--
 * @descripttion:
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div>
    <Loading v-if="loading"/>
    <router-view v-else></router-view>
  </div>
</template>
<script>
import Loading from './components/loading.vue'
import config from './js/config.js'
import wx from 'weixin-js-sdk';
export default {
  name: 'App',
  data() {
    return {
      loading: true,
      startLoading: false,
      music: config.res + '/music.mp3',
      count: 0,
      res: [{
        type: 0,
        url: config.res + '/xhl.jpg'
      }, {
        type: 0,
        url: config.res + '/imgs/home.jpg'
      }]
    }
  },
  watch: {
    count:{
      handler: async function (val) {
        if (val === this.res.length && this.startLoading && this.loading){
          setTimeout(() => {
            this.loading = false
          }, 1000)
        }
      },
      deep: true
    },
  },
  mounted() {
    document.addEventListener("WeixinJSBridgeReady", function () {
      WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
      WeixinJSBridge.on('menu:setfont', function() {
        WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
      })
    }, false);
    this.res.push({
      type: 1,
      url: this.music
    })
    for(let i=1; i< 13; i++) {
      this.res.push({
        type: 0,
        url: config.res + '/imgs/'+i+'.jpg'
      })
    }
    for(let i=1; i< 27; i++) {
      this.res.push({
        type: 0,
        url: config.res + '/imgs/xhl/'+i+'.jpg'
      })
    }
    this.startLoading = true
    this.res.forEach(d => {
      if (d.type === 0) {
        const image = new Image()
        image.src = d.url;
        image.onload = () => {
          this.count++
        }
        image.addEventListener('error', e => {
          this.count++
        });
      } else if (d.type === 1) {
        const audio =  new Audio();
        audio.src = d.url;
        audio.addEventListener('canplaythrough', () => {
          this.count++
        });
        audio.addEventListener('error', e => {
          this.count++
        });
        audio.load()
      } else {
        this.count++
      }
    })

  },
  components: {
    Loading
  }

}
</script>