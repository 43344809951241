/**
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from "@/pages/index.vue";
import Map from "@/pages/11.vue";
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '', component: HomePage },
    { path: '/map', component: Map }
  ]
})

export default router
