<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-17
-->
<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div id="OneSt">
    <div class="title">
      <div>一岁一礼</div>
      <div>欢迎您来见证我的成长</div>
    </div>
    <div class="foot">
      <!-- <div class="img">
        <img src="../assets/imgs/12-1.png">
      </div> -->
      <Map class="map"/>
    </div>
    <MonthList :curIndex="pageData.index"/>
  </div>
</template>

<script>
import MonthList from '../components/month.vue'
import Map from '../components/map.vue'
export default {
  name: 'OneSt',
  components: {
    MonthList,
    Map
  },
  props: {
    pageData: {
      type: Object,
      default: function () {
        return {
          index: 0,
          title: "暂无"
        }
      }
    }
  },

}
</script>

<style lang="scss">
#OneSt {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/12.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  margin: auto;
  text-align: center;
  position: relative;
  .title {
    margin-top: 36vh;
    color: #e3e3e3;
    font-size: 1.3rem;
    letter-spacing: 0.5rem;
    font-weight: 900;
    font-family: 'XHL';
    text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgb(81 72 164);
    div:first-child {
      font-size: 4rem;
    }
    padding: 0 6vw;
  }
  .foot {
    position: absolute;
    bottom: 2vh;
    .img {
      width: 100vw;
      img {
        width: 70vw;
        filter: drop-shadow(20px 20px 50px #70469c);
      }
    }
    .map {
      width: 66vw;
      margin-left: 17vw;
    }
  }
}
</style>
