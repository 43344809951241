<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-17
-->

<template>
  <div id="Map">
    <div class="time">
      <div class="text">{{ dateStr }}</div>
      <div class="text">距离宴会还有</div>
      <van-count-down v-if="time > 0" :time=time format="DD 天 HH 时 mm 分 ss 秒"/>
    </div>
    <div id="MapContainer"></div>
  </div>
</template>

<script>
import { CountDown } from 'vant'
import config from '../js/config.js'
export default {
  name: 'Map',
  components: {
    [CountDown.name]: CountDown,
  },
  data() {
    return {
      time: (config.time - Date.parse(new Date())),
      dateStr: ''
    }
  },
  mounted() {
    const date = new Date(config.time);
    this.dateStr = (date.getMonth() + 1)+ "月"+date.getDate()+"日 "+date.getHours()+":"+(date.getMinutes() > 9 ? date.getMinutes() : "0"+date.getMinutes())
    this.initMap()
  },
  methods: {
    initMap() {
      const center = new TMap.LatLng(config.address.lat,config.address.lng);
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      const map = new TMap.Map(document.getElementById('MapContainer'), {
        center: center,
        zoom: 15,
      });
      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM)
      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION)
      map.removeControl(TMap.constants.DEFAULT_CONTROL_ID.SCALE)
    
      const markerLayer = new TMap.MultiMarker({
        map: map,
        styles: {
          marker: new TMap.MarkerStyle({
            width: 20,
            height: 30,
            anchor: { x: 10, y: 30 },
          }),
        },
        //点标记数据数组
        geometries: [{
          "id": "1",
          "styleId": 'marker',
          "position": new TMap.LatLng(config.address.lat,config.address.lng),
          "properties": {
            "title": config.address.name
          }
        }
        ]
      });
      const clickHandler = function (evt) {
        location.href = "https://mapapi.qq.com/web/mapComponents/locationMarker/v/index.html?type=0&marker=coord:"+config.address.lat+","+config.address.lng+";title:"+config.address.name+";addr:" + config.address.detail
      }
      map.on("click", clickHandler)
      document.getElementsByClassName("logo-text")[0].style.display = "none"
    }
  }
}
</script>

<style lang="scss">
#Map {
  text-align: center;
  .time {
    .text {
      font-family: 'XHL';
      font-size: 1.1rem;
      line-height: 1.6rem;
      color: white !important;
    }
    .van-count-down {
      font-family: 'XHL';
      color: white !important;
      font-size: 1.1rem;
      line-height: 1.6rem;
    }
  }
  #MapContainer{
    canvas {
      overflow: hidden !important;
      border-radius: 10px !important;
    }
  }
}
</style>
