<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-16
-->
<template>
  <div id="Home">
    <div class="title">
      <div>小海螺</div>
      <div>喊你</div>
      <div>吃席啦</div>
    </div>
    <div class="btn" @click="start()">点击开始</div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  methods: {
    start() {
      this.$emit('start')
    }
  }
}
</script>

<style lang="scss">
#Home {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/home.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  margin: auto;
  text-align: center;
  z-index: 1;
  .title {
    margin-top: 4vh;
    font-size: 5.2rem;
    color: #e3e3e3;
    letter-spacing: 0.2rem;
    font-weight: bold;
    font-family: 'XHL';
    text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgb(81 72 164);
  }
  .btn {
    margin: 2vh auto 0 auto;
    font-size: 1.6rem;
    color: #e3e3e3;
    font-family: 'XHL';
    background: #4558ac;
    width: 10rem;
    padding: 0.6rem 0;
    border-radius: 3rem;
    box-shadow: 2px 2px 2px 3px #303e7f;
    animation: btn 2s infinite;
  }

  @keyframes btn{
	    0%{
	        transform: scale(1);
        box-shadow: 2px 2px 2px 3px #303e7f;
	    }
	    50%{
	        transform: scale(1.2);
          box-shadow: 3px 3px 3px 20px rgba(48,62,127, 0.2);
	    }
      100%{
	        transform: scale(1);
          box-shadow: 2px 2px 2px 3px #303e7f;
	    } 
	}
}
</style>
