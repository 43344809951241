<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-18
-->
<!--
 * @descripttion: 
 * @author: luohong
 * @Date: 2024-01-15
-->
<template>
  <div id="MayPage">
    <div class="text">
      <div class="text-2">想想去哪儿玩？</div>
    </div>
    <div class="img-2">
      <div class="img-item">
        <img :src="res + '/imgs/xhl/9.jpg'">
      </div>
    </div>
    <div class="img-1">
      <div class="img-item">
        <img :src="res + '/imgs/xhl/10.jpg'">
      </div>
      <div class="img-item">
        <img :src="res + '/imgs/xhl/11.jpg'">
      </div>
    </div>
    <MonthList :curIndex="pageData.index"/>
  </div>
</template>

<script>
import MonthList from '../components/month.vue'
import config from '../js/config.js'
export default {
  name: 'MayPage',
  components: {
    MonthList
  },
  data() {
    return {
      res: config.res
    }
  },
  props: {
    pageData: {
      type: Object,
      default: function () {
        return {
          index: 0,
          title: "暂无"
        }
      }
    }
  },

}
</script>

<style lang="scss">
#MayPage {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-image: url($res-url + "/imgs/5.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  font-family: "XHL";
  color: white;
  position: relative;
  .text {
    position: absolute;
    top: 6vh;
    font-size: 2rem;
    left: 2vw;
    letter-spacing: 1rem;
    line-height: 3rem;
    text-shadow: 1px -1px #fff, -1px 1px #999, -8px 8px 6px rgba(0,0,0,0.5);
  }
  .img-2{
    position: absolute;
    top: 20vh;
    left: 2vw;
    .img-item{
      display: flex;
      img{
        border-radius: 2rem;
        width: 50vw;
        filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
      }
    }
  }
  .img-1{
    position: absolute;
    bottom: 2vh;
    right: 8vw;
    width: 88vw;
    display: flex;
    align-items: center;
    .img-item{
      flex: 1;
      display: flex;
      img{
        border-radius: 2rem;
        width: 40vw;
        filter: drop-shadow(2px 2px 60px rgba(0,0,0,0.3));
      }
    }
  }
}
</style>
